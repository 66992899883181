

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment-timezone';

Vue.use(VueAxios, axios);

@Component({})
export default class FlightDetailModal extends Vue {
  @Prop() private flight!: any;

  @Prop() private message!: any;

  flightDetail: any = {};

  refreshing = false;

  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent: any, modalId: string) => {
      if (modalId === `flight-modal-from-message-${this.message.id}`) {
        this.refresh();
      }
    });
  }

  refresh() {
    console.log(`Fetching flight detail for ${this.flight.id}...`);
    this.refreshing = true;
    Vue.axios.get(`${this.$store.state.apiServerBaseUrl}/flights/${this.flight.id}`).then((response) => {
      console.log('Fetched flight.');
      console.log(response.data);
      const flightDetail = response.data;
      flightDetail.messages = flightDetail.messages.sort((a: any, b: any) => {
        if (a.messageNumber == null || b.messageNumber == null) return 0;
        const msgA = a.messageNumber.toUpperCase();
        const msgB = b.messageNumber.toUpperCase();
        if (msgA < msgB) {
          return -1;
        }
        if (msgA > msgB) {
          return 1;
        }
        return 0;
      });
      this.flightDetail = flightDetail;
      this.refreshing = false;
    });
  }
}
