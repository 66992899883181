


































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Version } from './utilities/version';

const CryptoJS = require('crypto-js');

@Component({
})
export default class App extends Vue {
  messagesLivePerSecondInterval = 0;

  version = new Version();

  handleLogout() {
    this.$store.dispatch('auth/logout');
    this.$router.push('/auth/login');
  }

  mounted() {
    this.$gtag.event('main_page_mounted');
    this.$data.messagesLivePerSecondInterval = setInterval(this.updateMessagesListPerSecond, 5000);
  }

  pauseLiveMessages() {
    console.log('Pausing live messages.');
    this.$store.commit('pauseLiveMessages');
  }

  playLiveMessages() {
    console.log('Playing live messages.');
    this.$store.commit('playLiveMessages');
  }

  updateMessagesListPerSecond() {
    this.$store.commit('calculateMessagesLivePerSecond');
  }

  emailImageUrl() {
    if (this.$store.state.auth.user.email) {
      return this.gravatarUrl(this.$store.state.auth.user.email, { size: 24 });
    }
    return '//ssl.gstatic.com/accounts/ui/avatar_2x.png';
  }

  gravatarUrl(email: String, options: any) { // eslint-disable-line class-methods-use-this
    const size = options.size || 100;
    const md5 = CryptoJS.MD5(email);
    return `https://www.gravatar.com/avatar/${md5}?s=${size}&d=%2F%2Fssl.gstatic.com%2Faccounts%2Fui%2Favatar_2x.png`;
  }
}

