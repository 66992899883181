






















































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MessageItemFooter extends Vue {
  @Prop() private message!: any;

  frequencyName(frequency: number) : string {
    const frequencyString = frequency.toFixed(3);
    const frequencyObj = this.$store.state.acarsData.frequencies[frequencyString];
    let frequencyName = 'Unknown';

    if (frequencyObj) {
      frequencyName = frequencyObj.name;
    }

    return frequencyName;
  }

  labelName(label: string) : string {
    const labelObj = this.$store.state.acarsData.labels[label];
    let labelName = 'Unknown';

    if (labelObj) {
      labelName = labelObj.name;
    }

    return labelName;
  }
}
