


























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MessageDecodingTable extends Vue {
  @Prop() private decodedMessage!: any;
}
