






















import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment-timezone';

import ActiveAirframes from '@/components/ActiveAirframes.vue';
import LoadingDots from '@/components/shared/LoadingDots.vue';
import MessageBox from '@/components/shared/MessageBox.vue';
import MessagesListItemSlim from '@/components/MessagesListItemSlim.vue';
import MessagesLivePaused from '@/components/messages/MessagesLivePaused.vue';

@Component({
  components: {
    ActiveAirframes,
    LoadingDots,
    MessageBox,
    MessagesListItemSlim,
    MessagesLivePaused,
  },
})
export default class MessagesList extends Vue {
  @Prop() private instructions!: String;

  @Prop({ default: false }) private isErrorGettingMessages!: boolean;

  @Prop({ default: false }) private isSearching!: boolean;

  @Prop() private messages!: Array<Object>;

  @Prop({ default: null }) private messagesCountMaximum!: Number;

  @Prop({ default: true }) private enableActiveAirframes!: boolean;

  @Prop({ default: true }) private enableFilters!: boolean;

  @Prop({ default: true }) private enablePopover!: boolean;

  searchingText = 'Searching for messages in the archives...';
}
