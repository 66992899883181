



























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MessageItemHeader extends Vue {
  @Prop() private message!: any;
}
