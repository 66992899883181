













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LoadingDots extends Vue {
  @Prop() private loadingText!: String;
}
