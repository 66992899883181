






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MessagesList extends Vue {
  @Prop() private message!: String;
}
