
































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { MessageDecoder } from '@airframes/acars-decoder/dist/MessageDecoder';
import FlightDetailModal from '@/components/flights/FlightDetailModal.vue';
import MessageItemHeader from '@/components/messages/MessageItemHeader.vue';
import MessageItemFooter from '@/components/messages/MessageItemFooter.vue';

@Component({
  components: {
    FlightDetailModal,
    MessageItemFooter,
    MessageItemHeader,
  },
})
export default class MessagesListItemSlim extends Vue {
  @Prop() private message!: any;

  @Prop({ default: true }) private enablePopover!: boolean;

  decodedMessage: any = {};

  convertNewlinesToBRs(text: string) : string { // eslint-disable-line class-methods-use-this
    return text ? text.split('\n').join('<br>') : text;
  }

  decodeMessage(message: any) : boolean { // eslint-disable-line class-methods-use-this
    const decoder = new MessageDecoder();
    this.decodedMessage = decoder.decode(message);
    return this.decodedMessage.decoded;
  }

  showFlightModal() {
    if (this.enablePopover) {
      this.$bvModal.show(`flight-modal-${this.message.flight.id}`);
    }
  }

  showMessageModal() {
    if (this.enablePopover) {
      this.$bvModal.show(`message-modal-${this.message.id}`);
    }
  }
}
